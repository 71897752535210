import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~6],
		"/account": [7],
		"/admin": [~8],
		"/automations": [17],
		"/automation": [~9,[2]],
		"/automation/new": [16,[2]],
		"/automation/[id]": [~10,[2],[,3]],
		"/automation/[id]/approval": [~11,[2],[,3]],
		"/automation/[id]/approval/[id]": [~12,[2],[,3,4]],
		"/automation/[id]/form": [~13,[2],[,3]],
		"/automation/[id]/form/[id]": [~14,[2],[,3,5]],
		"/automation/[id]/results": [~15,[2],[,3]],
		"/dashboard": [~18],
		"/demo": [19],
		"/login": [~20],
		"/setup": [~21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';